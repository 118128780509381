/**
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
 * Licensed under the Amazon Software License  http://aws.amazon.com/asl/
 */

import React from 'react';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { makeObservable, runInAction } from 'mobx';
import TimeAgo from 'react-timeago';
import { Header, Icon, Label, Image, Dimmer, Loader, Modal } from 'semantic-ui-react';
import Dotdotdot from 'react-dotdotdot';
import { displayError } from '../../helpers/notification';
import { withRouter } from '../../helpers/routing';
import EnvironmentStatusIcon from './EnvironmentStatusIcon';
import EditInstance from './EditInstance';
import By from '../helpers/By';
import EnvironmentConnectButton from './EnvironmentConnectButton';
import sagemakerNotebookIcon from '../../images/marketplace/sagemaker-notebook-icon.svg';
import emrIcon from '../../images/marketplace/emr-icon.svg';
import ec2Icon from '../../images/marketplace/ec2-icon.svg';
import parallelClusterIcon from '../../images/marketplace/parallel-cluster-icon.svg';
import EnvironmentTerminatePrompt from './EnvironmentTerminatePrompt';
import EnvironmentExportAmi from './EnvironmentExportAmi';

// expected props
// - environment - a Environment model instance (via props)
// - userDisplayName (via injection)
// - location (from react router)
// edited by 2359 - added ability to start or stop instance
// edit 2359 - add support for EMR-hail
class EnvironmentCard extends React.Component {
  constructor(props) {
    super(props);
    makeObservable(this, {});
    this.state = { isProcessing: false, total: null };
    runInAction(() => {
      // add any state changing initialization logic if needed
    });
  }

  openModal = async event => {
    event.preventDefault();
    event.stopPropagation();

    runInAction(() => {
      this.setState({
        openEditModal: true,
      });
    });
  };

  // for the form
  closeModal = async event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    runInAction(() => {
      this.setState({
        openEditModal: false,
      });
    });
  };

  async componentDidMount() {
    const environment = this.getEnvironment();
    const store = this.getStore();
    const costInfo = await store.getCost(environment.id, 1);
    let total = 0;
    if (_.isEmpty(costInfo[costInfo.length - 1].cost)) {
      total = 0;
    } else {
      const costsForLatestDate = costInfo[costInfo.length - 1].cost;
      Object.keys(costsForLatestDate).forEach(service => {
        total += costsForLatestDate[service].amount;
      });
    }

    runInAction(() => {
      this.setState({ total: total.toFixed(2) });
    });
    environment.setFetchingUrl(false);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  handleStartEnvironment = async event => {
    event.preventDefault();
    event.stopPropagation();
    runInAction(() => {
      this.setState({ isProcessing: true });
    });

    try {
      const store = this.getStore();
      await store.startEnvironment(this.getEnvironment());
      await store.doLoad();
      runInAction(() => {
        this.setState({ isProcessing: false });
      });
    } catch (error) {
      displayError(error);
      runInAction(() => {
        this.setState({ isProcessing: false });
      });
    }
  };

  handleStopEnvironment = async event => {
    event.preventDefault();
    event.stopPropagation();
    runInAction(() => {
      this.setState({ isProcessing: true });
    });

    try {
      const store = this.getStore();
      await store.stopEnvironment(this.getEnvironment());
      await store.doLoad();
      runInAction(() => {
        this.setState({ isProcessing: false });
      });
    } catch (error) {
      runInAction(() => {
        this.setState({ isProcessing: false });
      });
      displayError(error);
    }
  };

  getEnvironment() {
    return this.props.environment;
  }

  getStore() {
    return this.props.environmentsStore;
  }

  getUserDisplayNameService() {
    return this.props.userDisplayName;
  }

  // edit 2359 - add support for EMR-hail
  getIcon(type) {
    switch (type) {
      case 'sagemaker':
      case 'secure-sagemaker':
        return sagemakerNotebookIcon;
      case 'ami-emr':
      case 'emr':
      case 'secure-ami-emr':
      case 'secure-emr':
      case 'emr-hail':
        return emrIcon;
      case 'ami':
      case 'ami-ec2':
      case 'ec2-linux':
      case 'ec2-alinux2':
      case 'ec2-alinux2023':		    
      case 'ec2-windows':
      case 'secure-ami':
      case 'secure-ami-ec2':
      case 'secure-ec2-linux':
      case 'secure-ec2-alinux2':
      case 'secure-ec2-alinux2023':		    
      case 'secure-ec2-windows':
        return ec2Icon;
      case 'parallel-cluster':
        return parallelClusterIcon;
      default:
        return null;
    }
  }

  render() {
    const item = this.getEnvironment();
    return (
      <div className="flex">
        {this.renderLeftCard(item)}
        {this.renderRightCard(item)}
      </div>
    );
  }

  // edit 2359 - add support for EMR-hail
  renderLeftCard(env) {
    const { name, description, createdAt, createdBy, fetchingUrl, status, instanceInfo, error, amiIds, isSecure } = env;
    return (
      <div className="flex-auto">
        <div className="flex">
          <Image
            src={this.getIcon(instanceInfo.type)}
            className="mt0 mr1"
            style={{ maxHeight: '24px', maxWidth: '24px' }}
          />
          {isSecure && <Icon name="lock" />}
          <Header as="h3" color="grey" className="mt0 flex-auto">
            {name}
          </Header>
          <div className="flex">
            <Label className="flex-auto basic" style={{ border: 0 }}>
              {status === 'COMPLETED' &&
                (instanceInfo.type === 'sagemaker' ||
                  instanceInfo.type === 'emr' ||
                  instanceInfo.type === 'grids-emr') && (
                  <EnvironmentConnectButton as={Label} environment={env} size="mini" color="green">
                    {fetchingUrl ? (
                      <>
                        Connecting
                        <Icon loading name="spinner" size="small" className="ml1 mr1" />
                      </>
                    ) : (
                      <>Connect</>
                    )}
                  </EnvironmentConnectButton>
                )}
              {status === 'COMPLETED' && instanceInfo.type === 'emr-hail' && (
                <EnvironmentConnectButton as={Label} environment={env} size="mini" color="green">
                  {fetchingUrl ? (
                    <>
                      Connecting
                      <Icon loading name="spinner" size="small" className="ml1 mr1" />
                    </>
                  ) : (
                    <>Connect Zeppelin</>
                  )}
                </EnvironmentConnectButton>
              )}
              {status === 'COMPLETED' && instanceInfo.type === 'emr-hail' && (
                <EnvironmentConnectButton as={Label} environment={env} size="mini" color="green" isSpark>
                  {fetchingUrl ? (
                    <>
                      Connecting
                      <Icon loading name="spinner" size="small" className="ml1 mr1" />
                    </>
                  ) : (
                    <>Connect SparkUI</>
                  )}
                </EnvironmentConnectButton>
              )}
            </Label>
            <Label className="flex-auto basic" style={{ border: 0 }}>
              <EnvironmentStatusIcon environment={env} />
            </Label>
          </div>
        </div>
        <div className="ml3 mb2 mt2 breakout">
          created <TimeAgo date={createdAt} /> <By user={createdBy} />{' '}
          {env.createdFromAmi ? (
            <div>
              from <span className="bold">AMI: </span>
              {env.createdFromAmi}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="ml3 mb2 mt2 breakout">
          <Dotdotdot clamp={3}>
            <div style={{ 'white-space': 'pre-line' }}>{description}</div>
          </Dotdotdot>
        </div>
        {amiIds && amiIds.length && (
          <div className="ml3 mb2 mt2 breakout">
            <Label>{`Last Exported as AMI: ${amiIds[amiIds.length - 1]}`}</Label>
          </div>
        )}
        <div className="ml3 mb2 mt2 breakout bold">
          Yesterday&apos;s Research Workspace Cost: ${this.state.total ? this.state.total : <Loader />}
        </div>
        {error && (
          <div className="ml3 mb2 mt2 breakout error text w-50">
            Please contact administrators at raptor_admin@gis.a-star.edu.sg with your workspace id {env.id}.
          </div>
        )}
      </div>
    );
  }

  /* getCostInPastDay(costInfo) {
    if (_.isEmpty(costInfo)) {
      return 0;
    }
    const costsForLatestDate = costInfo[costInfo.length - 1].cost;
    let total = 0;
    costsForLatestDate.forEach(service => {
      total += service.amount;
    });
    return total.toFixed(2);
  } */

  // edit 2359 - add support for EMR-hail
  // edit chenjqp - add support for secure emr-hail
  renderRightCard(environment) {
    const displayNameService = this.getUserDisplayNameService();
    return (
      <div className="border-left border-grey pl2 ml2">
        <div className="mt1 fs-9">
          <span className="bold  inline-block">Research Workspace Owners</span>{' '}
          <Label circular size="mini" color="blue">
            {1}
          </Label>
        </div>
        <div className="fs-9">
          <Dotdotdot clamp={1}>{displayNameService.getLongDisplayName(environment.createdBy)}</Dotdotdot>
        </div>
        <div className="fs-9">
          <Dotdotdot clamp={1}>{[]}</Dotdotdot>
        </div>

        <div className="mt3 fs-9">
          <span className="bold  inline-block">Project</span>{' '}
        </div>
        <div className="fs-9 mb2">
          <Dotdotdot clamp={1}>{environment.projectId}</Dotdotdot>
        </div>
        <Dimmer.Dimmable className="minHeight-50" dimmed={this.state.isProcessing}>
          <Dimmer active={this.state.isProcessing} inverted>
            <Loader size="small" />
          </Dimmer>
          {environment.instanceInfo.type !== 'emr' &&
            environment.instanceInfo.type !== 'emr-hail' &&
            environment.instanceInfo.type !== 'secure-vetting' &&
            environment.instanceInfo.type !== 'secure-emr-hail' &&
            environment.instanceInfo.type !== 'secure-sagemaker' && (
              <div className="mb1 mt5 breakout">{this.renderStopButton(environment)}</div>
            )}
          <div className="mb1 mt5 breakout">{this.renderTerminateButton(environment)}</div>
          {(environment.instanceInfo.type === 'ec2-linux' ||
            environment.instanceInfo.type === 'ec2-alinux2' ||
            environment.instanceInfo.type === 'ec2-alinux2023' ||		  
            environment.instanceInfo.type === 'ec2-windows' ||
            environment.instanceInfo.type === 'ami') &&
            (environment.status === 'STOPPED') && (
              <div className="mb1 mt5 breakout" onClick={e => e.stopPropagation()}>
                {this.renderExportButton(environment)}
              </div>
            )}
          {(environment.instanceInfo.type === 'ec2-linux' ||
            environment.instanceInfo.type === 'secure-ec2-linux' ||
            environment.instanceInfo.type === 'ec2-alinux2' ||
            environment.instanceInfo.type === 'secure-ec2-alinux2' ||
	    environment.instanceInfo.type === 'ec2-alinux2023' || 
	    environment.instanceInfo.type === 'secure-ec2-alinux2023') &&
            environment.status === 'STOPPED' && (
              <div className="mb1 mt5 breakout">{this.renderEditInstanceButton(environment)}</div>
            )}
        </Dimmer.Dimmable>
      </div>
    );
  }

  renderEditInstanceButton(environment) {
    return (
      <>
        <Label color="blue" className="cursor-pointer" data-id={environment} onClick={this.openModal}>
          <Icon name="pencil alternate" />
          Edit Instance
        </Label>
        <Modal closeIcon open={this.state.openEditModal} onClose={this.closeModal}>
          <div className="mt2 animated fadeIn">
            <Header as="h3" icon textAlign="center" className="mt3" color="grey">
              Edit Instance Size
            </Header>
            <div className="mx3 animated fadeIn">
              <EditInstance
                environment={environment}
                environmentsStore={this.props.environmentsStore}
                onClose={this.closeModal}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }

  renderStopButton(environment) {
    let stopButton;
    if (environment.isCompleted || environment.isStopFailed) {
      stopButton = (
        <Label color="orange" className="cursor-pointer" data-id={environment.id} onClick={this.handleStopEnvironment}>
          <Icon name="stop" />
          Stop
        </Label>
      );
    } else if (environment.isStopped || environment.isStartFailed) {
      stopButton = (
        <Label color="green" className="cursor-pointer" data-id={environment.id} onClick={this.handleStartEnvironment}>
          <Icon name="play" />
          Start
        </Label>
      );
    }

    return <>{stopButton}</>;
  }

  renderExportButton(environment) {
	return <EnvironmentExportAmi environment={environment} environmentsStore={this.props.environmentsStore}/>
  }

  renderTerminateButton(environment) {
    let terminateButton;
    if (environment.isCompleted || environment.isStopFailed || environment.isStopped) {
      terminateButton = (
        <div className="mb1 mt5 breakout" onClick={e => e.stopPropagation()}>
          <EnvironmentTerminatePrompt environmentsStore={this.getStore()} environment={this.getEnvironment()} />
        </div>
      );
    }

    return <>{terminateButton}</>;
  }
}

export default inject('userDisplayName')(withRouter(observer(EnvironmentCard)));
