// import sagemakerNotebookIcon from '../../../images/marketplace/sagemaker-notebook-icon.svg';
import emrIcon from '../../../images/marketplace/emr-icon.svg';
import ec2Icon from '../../../images/marketplace/ec2-icon.svg';
import parallelClusterIcon from '../../../images/marketplace/parallel-cluster-icon.svg';
import sagemakerNotebookIcon from '../../../images/marketplace/sagemaker-notebook-icon.svg';

// edit 2359 - add support for parallel cluster
// edit 2359, chenjqp - add support for EMR-hail
const gallery = {
  /*
  id2: {
        type: 'emr-hail',
        name: 'EMR Hail',
        sub: 'Popular for small to large genomic workloads',
        description: () => (
          <div>
            An Amazon EMR research workspace that comes with:
            <ul>
              <li>Hail 0.2</li>
              <li>Ganglia</li>
              <li>Hadoop</li>
              <li>JupyterHub</li>
              <li>Livy</li>
              <li>Spark</li>
              <li>Zeppelin</li>
            </ul>
          </div>//
        ),
        icon: emrIcon,
      }, */
  id6: {
    type: 'ec2-alinux2',
    name: 'EC2 - Amazon Linux 2',
    sub: 'Secure, resizable compute in the cloud (Available till 30 Jun 2025)',
    description: () => null,
    icon: ec2Icon,
  },
  id7: {
    type: 'ec2-alinux2023',
    name: 'EC2 - Amazon Linux 2023',
    sub: 'Secure, resizable compute in the cloud',
    description: () => null,
    icon: ec2Icon,
  },	
  id5: {
    type: 'parallel-cluster',
    name: 'Parallel Cluster',
    sub: 'Secure, resizable compute in the cloud',
    description: () => null,
    icon: parallelClusterIcon,
  },
};

// add chenjqp - gallery for EMR only
const emrGallery = {
  id2: {
    type: 'emr-hail',
    name: 'EMR Hail',
    sub: 'Popular for small to large genomic workloads',
    description: () => (
      <div>
        An Amazon EMR research workspace that comes with:
        <ul>
          <li>Hail 0.2</li>
          <li>Ganglia</li>
          <li>Hadoop</li>
          <li>JupyterHub</li>
          <li>Livy</li>
          <li>Spark</li>
          <li>Zeppelin</li>
        </ul>
      </div> //
    ),
    icon: emrIcon,
  },
};

const amiGallery = {
  id2: {
    type: 'ec2-alinux2023',
    name: 'EC2 - Linux',
    sub: 'Secure, resizable compute in the cloud',
    description: () => null,
    icon: ec2Icon,
  },
};

const ec2Gallery = {
  id6: {
    type: 'ec2-alinux2',
    name: 'EC2 - Amazon Linux 2',
    sub: 'Secure, resizable compute in the cloud (Available till 30 Jun 2025)',
    description: () => null,
    icon: ec2Icon,
  },
  id7: {
    type: 'ec2-alinux2023',
    name: 'EC2 - Amazon Linux 2023',
    sub: 'Secure, resizable compute in the cloud',
    description: () => null,
    icon: ec2Icon,
  },	  	
  id5: {
    type: 'ami-parallel-cluster',
    name: 'Parallel Cluster',
    sub: 'Secure, resizable compute in the cloud',
    description: () => null,
    icon: parallelClusterIcon,
  },
};

// edit chenjqp - add support for secure emr hail
const secureGallery = {
  id6: {
    type: 'secure-ec2-alinux2',
    name: 'EC2 - Amazon Linux 2',
    sub: 'Secure, resizable compute in the cloud (Available till 30 Jun 2025)',
    description: () => null,
    icon: ec2Icon,
  },
  id8: {
    type: 'secure-ec2-alinux2023',
    name: 'EC2 - Amazon Linux 2023',
    sub: 'Secure, resizable compute in the cloud',
    description: () => null,
    icon: ec2Icon,
  },	
  id2: {
    type: 'secure-emr-hail',
    name: 'EMR Hail',
    sub: 'Popular for small to large genomic workloads',
    disabledReason: 'EOL - Under Maintenance',
    description: () => (
      <div>
        An Amazon EMR research workspace that comes with:
        <ul>
          <li>Hail 0.2</li>
          <li>Ganglia</li>
          <li>Hadoop</li>
          <li>JupyterHub</li>
          <li>Livy</li>
          <li>Spark</li>
          <li>Zeppelin</li>
        </ul>
      </div> 
    ),
    icon: emrIcon,
  },
  id7: {
    type: 'secure-sagemaker',
    name: 'Sagemaker Jupyter Notebook',
    sub: 'Compute instance running the Jupyter Notebook application',
    description: () => null,
    icon: sagemakerNotebookIcon,
  },
};

const amiSecureGallery = {
  id3: {
    type: 'secure-ec2-alinux2023',
    name: 'EC2 - Linux',
    sub: 'Secure, resizable compute in the cloud',
    description: () => null,
    icon: ec2Icon,
  },
};

export { gallery, amiGallery, ec2Gallery, secureGallery, amiSecureGallery, emrGallery };
